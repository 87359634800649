import React, { useEffect } from 'react'
import { ReactiveList } from '@appbaseio/reactivesearch'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap, faSlash, faGlobe } from '@fortawesome/free-solid-svg-icons'
import reactArray from '../components/reactArray'
import ExportCsv from '../components/ExportCsv'
import handleHtmlMarkup from '../components/HandleHtmlMarkup'
import Fields from '../components/Fields'

library.add(faGlobe)
library.add(faSitemap)
library.add(faSlash)

/*FreeSearch component list search results based on the selected filters. The Reactive Search components is watched via their React prop. */

function FreeSearch(props) {

    useEffect(() => {
        document.getElementsByClassName('sort-options')[0].setAttribute("title", "Vælg hvilket felt søgeresultater skal matches mod");
    });


    /* Default query for the ResultList component. The query should match the Elastic Search query DSL. */
    const resultListDefaultQuery = () => {
        return {
          sort: [{ "EntityName.keyword": { order: "asc" } }],
          track_total_hits: true,
          query: { match_all: {} },
        };
    }

    const renderSpeciality = (array) => {
        let prettyString = ""
        if (array != null) {
            prettyString = array.join(", ")
        } else {
            prettyString = ""
        }
        return prettyString
    }

    const renderEnhed = (res) => {

        if (res) {
            return (
                <div className="enhed" key={res._id}>
                    <div className="row">
                        <div className="col-12 col-lg-10 mt-9" onClick={() => props.handleClick(res._id)}>
                            <div className="entityName" dangerouslySetInnerHTML={handleHtmlMarkup(res.EntityName)} />
                        </div>
                        <div className="col-12 col-lg-2 search-results__button-row">
                            {res.PostalAddressGeoLocation &&
                                <button type="button" data-toggle="tooltip" data-placement="right" title="Visning på kort" className="btn btn-default me-3 search-results__btn" onClick={() => props.handleMapClick(res._id)
                                } > <FontAwesomeIcon icon="globe" /></button>
                            }
                            {!res.PostalAddressGeoLocation &&
                                <button className="btn btn-default me-3 search-results__btn" type="button" data-toogle="tooltip" data-placement="right" title="Denne enhed kan ikke vises på et kort"><FontAwesomeIcon icon="slash" /></button>

                            }
                            {res.Treelist && res.InstitutionOwnerEntityTypeName &&
                                <button type="button" data-toggle="tooltip" data-placement="right" title="Visning i organisatorisk træ" className="btn btn-default search-results__btn" onClick={() => props.handleTreeClick(res._id, res.InstitutionOwnerEntityTypeName, res.InstitutionOwnerEntityName, res.Treelist)}><FontAwesomeIcon icon="sitemap" /></button>
                            }
                            {!res.Treelist &&
                                <button className="btn btn-default me-3 search-results__btn" type="button" data-toogle="tooltip" data-placement="right" title="Denne enhed kan ikke vises på et kort"><FontAwesomeIcon icon="slash" /></button>
                            }
                        </div>

                    </div>
                    <div className="row search-results" onClick={() => props.handleClick(res._id)}>
                        {props.showFields.indexOf("CheckEntityTypeName") !== -1 &&
                            <div className="col-6 col-lg-5">
                                <label>Enhedstype:</label> {res.EntityTypeName}
                            </div>
                        }

                        {props.showFields.indexOf("CheckAddress") !== -1 &&
                            <div className="col-6 col-lg-5">
                                <label>Adresse:</label>
                                {res.SorType === "IE" &&
                                    <React.Fragment><span dangerouslySetInnerHTML={handleHtmlMarkup(res.PostalAddress)} /><span> {res.PostalPostCodeId} {res.PostalDistrictName}</span></React.Fragment>
                                }
                                {res.SorType !== "IE" &&
                                    <React.Fragment><span dangerouslySetInnerHTML={handleHtmlMarkup(res.VisitingAddress)} /><span> {res.VisitingAddressPostCodeId} {res.VisitingAddressDistrictName}</span></React.Fragment>
                                }

                            </div>
                        }
                        {props.showFields.indexOf("CheckSpecialityName") !== -1 &&
                            <div className="col-6 col-lg-5">
                                <label>Speciale:</label> {renderSpeciality(res.SpecialityName)}
                            </div>
                        }
                        {props.showFields.indexOf("CheckInstitutionOwnerCvrNumberId") !== -1 &&
                            <div className="col-6 col-lg-5">
                                <label>CVR nummer:</label> <span dangerouslySetInnerHTML={handleHtmlMarkup(res.InstitutionOwnerCvrNumberId)} />
                            </div>
                        }
                        {props.showFields.indexOf("CheckSorId") !== -1 &&
                            <div className="col-6 col-lg-5">
                                <label>SOR. kode:</label> <span dangerouslySetInnerHTML={handleHtmlMarkup(res.SorId)} />
                            </div>
                        }
                        {props.showFields.indexOf("CheckShakId") !== -1 &&
                            <div className="col-6 col-lg-5">
                                <label>SHAK kode:</label><span dangerouslySetInnerHTML={handleHtmlMarkup(res.ShakId)} />
                            </div>
                        }
                        {props.showFields.indexOf("CheckEanLocationCode") !== -1 &&
                            <div className="col-6 col-lg-5 optional-element">
                                <label>Lokationsnummer:</label> <span dangerouslySetInnerHTML={handleHtmlMarkup(res.EanLocationCode)} />
                            </div>
                        }
                        {props.showFields.indexOf("CheckProviderId") !== -1 &&
                            <div className="col-6 col-lg-5">
                                <label>Ydernummer:</label> <span dangerouslySetInnerHTML={handleHtmlMarkup(res.ProviderId)} />
                            </div>

                        }
                        {props.showFields.indexOf("CheckPharmacyId") !== -1 &&
                            <div className="col-6 col-lg-5">
                                <label>Apoteksnummer:</label> <span dangerouslySetInnerHTML={handleHtmlMarkup(res.PharmacyId)} />
                            </div>
                        }
                    </div>
                </div>
            )
        }
    }

    return (
        <React.Fragment>
            <div className="col-12  mb-3">
                <div className="justify-content-end"  style={{ display: 'flex'}}>
                    <ExportCsv />
                    <Fields showFields={props.showFields} handleShowFieldsClick={props.handleShowFieldsClick} />
                </div>
            </div>

            <ReactiveList
                componentId="ResultList"
                dataField="EntityName"
                from={0}
                size={10}
                pagination={false}
                target="_self"
                renderNoResults={() => <div>Ingen enheder fundet.</div>}
                loader="Henter SOR enheder..."
                react={{
                    and: reactArray("ResultList")
                }}
                defaultQuery={resultListDefaultQuery}
                renderItem={res => renderEnhed(res)}
                sortOptions={[
                    {
                        label: "Enhedsnavn",
                        dataField: "EntityName.keyword",
                        sortBy: "asc"
                    },
                    {
                        label: "SOR type",
                        dataField: "Level.keyword",
                        sortBy: "asc"
                    },
                    {
                        label: "SOR. kode",
                        dataField: "SorId.keyword",
                        sortBy: "desc"
                    },
                    {
                        label: "CVR",
                        dataField: "InstitutionOwnerCvrNumberId.keyword",
                        sortBy: "desc"
                    },
                    {
                        label: "SHAK kode",
                        dataField: "ShakId.keyword",
                        sortBy: "desc"
                    },
                    {
                        label: "Ydernummer",
                        dataField: "ProviderId.keyword",
                        sortBy: "desc"
                    },
                    {
                        label: "Apoteksnummer",
                        dataField: "PharmacyId.keyword",
                        sortBy: "desc"
                    },
                    {
                        label: "Lokationsnummer",
                        dataField: "EanLocationCode.keyword",
                        sortBy: "desc"
                    },
                    {
                        label: "Bedste match",
                        dataField: "_score",
                        sortBy: "desc"
                    }

                ]}
                innerClass={{
                    title: 'search-results__title',
                    sortOptions: 'mb-3 btn freesearch sort-options custom-select'
                }}
            />
        </React.Fragment>

    );

}
export default FreeSearch

