import React, { useState } from 'react'
import { ReactiveList } from '@appbaseio/reactivesearch'
import reactArray from '../components/reactArray'

function ExportCsv() {

    const [doExport, setDoExport] = useState(false);

    const handleOnData = (res) => {
        if (res.length !== 0) {
            setDoExport(false);
            Csv(res)
        }
    }

    const handleExportClick = (e) => {
        e.preventDefault()
        setDoExport(true);
    }

    const Csv = (json) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", process.env.REACT_APP_CSV_API_URL, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        xhr.responseType = 'blob';
        xhr.onload = function (event) {
            var blob = this.response;
            var contentDispo = xhr.getResponseHeader('Content-Disposition');
            var fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            a.click();
        }
        xhr.send(new Blob([JSON.stringify(json)], { type: 'application/json' }));

    }

    //Loader er fjernet men kan genindsættes.
    return (
        <React.Fragment>
            <button type="button" title="Eksporter dit søgeresultat til CSV " className="btn btn-sm btn-light btn-outline-dark me-3" onClick={(e) => handleExportClick(e)}>Eksporter</button>

            {doExport &&

                <ReactiveList
                    componentId="ExportCsvList"
                    dataField="EntityName"
                    showResultStats={false}
                    size={5000}
                    loader={/*<div className="btn btn-sm btn-light me-3">Eksporterer til CSV</div>*/<div></div>}
                    react={{
                        and: reactArray("ExportCsvList")
                    }}
                    render={({
                        loading,
                        error,
                        data,
                    }) => handleOnData(data)}
                />
            }

        </React.Fragment>
    )
}

export default ExportCsv
